import {
  FiX
} from 'react-icons/fi';

import { ModalArea } from "./styles";

import { SolicitacaoData } from '../../types/solicitacao';
import { useMemo, useState } from "react";
import { toast } from "react-hot-toast";

import firebase from '../../services/firebaseConnect';
import axios from 'axios';

type ALL_PROPS = {
  solicitacoes: any[];
  closeModal: () => void;
}

export default function ModalSolicitacoes({ solicitacoes, closeModal }: ALL_PROPS) {

  const [allSolicitacoes, setAllSolicitacoes] = useState<any[] | []>(solicitacoes);
  const [statusSelected, setStatusSelected] = useState('');
  const [loading, setLoading] = useState(false);
  const [filterSelected, setFilterSelected] = useState('');
  //
  const [observation, setObservation] = useState('');
  const [showObservation, setShowObservation] = useState<any | null>(null);
  const [filterDateType, setFilterDateType] = useState('recentes');

  const filteredListing = useMemo(() => {
    return allSolicitacoes?.filter(item => filterSelected ? item.status.toLowerCase().includes(filterSelected.toLowerCase()) : true)
  }, [allSolicitacoes, filterSelected]);

  const filteredDate = useMemo(() => {
    if (filterDateType === 'recentes') {
      //@ts-ignore
      const newList = [...filteredListing].sort((a, b) => parseDate(b.dt_inclusion) - parseDate(a.dt_inclusion));
      // console.log('RECENTES: ', newList)
      return newList;
    }
    if (filterDateType === 'antigos') {
      //@ts-ignore
      const newList = [...filteredListing].sort((a, b) => parseDate(a.dt_inclusion) - parseDate(b.dt_inclusion));
      // console.log('ANTIGAS: ', newList)
      return newList;
    }
  }, [filteredListing, allSolicitacoes, filterDateType]);

  function parseDate(dateStr: any) {
    const [day, month, year] = dateStr.split("/").map(Number);
    return new Date(2000 + year, month - 1, day);
  }

  const handleUpdateStatus = async (solicitacao: any) => {
    setLoading(true);

    if (!statusSelected) {
      toast.error('Selecione um status para poder atualizar!');
      setLoading(false);
      return;
    }


    try {

      await axios.patch('https://camaraosasco.mavidey.com.br/request', {
        idRequest: solicitacao.id_request,
        status: statusSelected
      },
        { withCredentials: true }
      )

      toast.success('Status atualizado com sucesso!');


    }
    catch (error) {
      toast.error('Houve um erro! Tente novamente em alguns minutos.')
      // console.log(error);
    }
    finally {
      setLoading(false);
    }
  }

  const handleUpdateObservation = async (solicitacao: SolicitacaoData) => {
    if (!observation) {
      toast.error('Adicione uma observação para poder atualizar!');
      return;
    }

    setLoading(true);
    try {

      await firebase.firestore().collection('solicitacoes').doc(solicitacao.id).update({
        observation: observation
      })
        .then(async () => {

          const filtered = await allSolicitacoes.filter(item => item.id !== solicitacao.id);
          solicitacao.observation = observation;
          setAllSolicitacoes([solicitacao, ...filtered]);
          toast.success('Observação atualizada com sucesso!');
          setObservation('');
          // router.reload();

        })

    }
    catch (error) {
      toast.error('Houve um erro! Tente novamente em alguns minutos.')
      // console.log(error);
    }
    finally {
      setLoading(false);
    }

  }

  const handleRemoveSolicitation = async (solicitacao: SolicitacaoData) => {
    setLoading(true);

    try {

      await firebase.firestore().collection('solicitacoes').doc(solicitacao.id).delete()
        .then(async () => {

          if (solicitacao.imagem.length > 0) {
            await deleteImages(solicitacao);
          }

          const filtered = await allSolicitacoes.filter(item => item.id !== solicitacao.id);
          setAllSolicitacoes(filtered)
          toast.success('Solicitação removida com sucesso!');
        })

    }
    catch (error) {
      // console.log(error)
    }
    finally {
      setLoading(false)
    }
  }

  const deleteImages = async (solicitacao: SolicitacaoData) => {
    for (let i = 0; i < solicitacao.imagem.length; i++) {
      const imageUrl = solicitacao.imagem[i];
      const fileRef = firebase.storage().refFromURL(imageUrl);
      try {
        await fileRef.delete();
        // console.log(`Imagem ${imageUrl} excluída com sucesso!`);
      } catch (error) {
        // console.error(`Erro ao excluir imagem ${imageUrl}:`, error);
      }
    }
  }

  return (
    <ModalArea>
      <button className="closeModal" onClick={() => closeModal()}>
        <FiX />
      </button>
      <div className="modalContent">

        <div className="header">
          <div>
            <h3>Solicitações: ({allSolicitacoes.length})</h3>
            <h2>{allSolicitacoes[0].politician_name}</h2>
          </div>
          <div
            style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
          >
            <select
              onChange={e => setFilterSelected(e.target.value)}
            >
              <option value="">Mostrar tudo</option>
              <option value="Aprovado">Aprovado</option>
              <option value="Em Aberto">Em Aberto</option>
              <option value="Em Andamento">Em Andamento</option>
              <option value="Concluída">Concluída</option>
              <option value="Cancelado">Cancelado</option>
              <option value="Pendente">Pendente</option>
            </select>
            {/*  */}
            <select
              onChange={e => setFilterDateType(e.target.value)}
            >
              <option value="recentes">Mais Recentes</option>
              <option value="antigos">Mais Antigos</option>
            </select>
          </div>
        </div>

        {
          filteredDate?.map(item => (
            <div key={item.id_request} className="solicitacaoItem">
              <div className="solicitacaoHeader">
                <p style={{ fontWeight: 'bold', color: '#2c2c2c' }}>{item.user_name}</p>
                <span>{item.data}</span>
              </div>
              <p style={{ color: '#2c2c2c' }}>
                <strong>Descrição:</strong>
                <br />
                {item.description}
                <div style={{ height: 1, width: '100%', backgroundColor: '#eee', marginTop: 5, color: '#2c2c2c' }}></div>
                {
                  <>
                    <p style={{ marginTop: 5, color: '#2c2c2c' }}>
                      <b>Bairro:</b> {item.neighborhood} | <b>Número:</b> {item.number} | <b>Logradouro:</b> {item.street} | <b>Cidade:</b> {item.city} | <b>CEP:</b> {item.zipcode} | <b>UF:</b> {item.uf} | <b>Complemento:</b> {item.complement} | <b>Ponto de referência:</b> {item.reference_point}
                    </p>
                    <div style={{ height: 1, width: '100%', backgroundColor: '#eee', marginTop: 5 }}></div>
                  </>
                }
              </p>

              {
                <>
                  {
                    item?.images.length > 0 &&
                    <div className="imagesArea" style={{ marginTop: 5 }}>
                      {
                        Array.isArray(item?.images) ?
                          <>

                            {
                              item?.images.map((uri: any) => (
                                <a target='_blank' href={uri.url_image_request?.includes("https://firebasestorage") ? uri.url_image_request : `https://camaraosasco.mavidey.com.br/${uri.url_image_request}`} key={uri.url_image_request} rel="noreferrer">
                                  <img
                                    key={uri.url_image_request}
                                    style={{ width: 50, height: 50, borderRadius: 2, marginRight: 5 }}
                                    src={uri.url_image_request?.includes("https://firebasestorage") ? uri.url_image_request : `https://camaraosasco.mavidey.com.br/${uri.url_image_request}`}
                                    alt='user image'
                                  />
                                </a>
                              ))
                            }
                          </>
                          :
                          <img
                            style={{ width: 50, height: 50, borderRadius: 2, marginRight: 5 }}
                            src={item.url_image_request?.includes("https://firebasestorage") ? item.url_image_request : `https://camaraosasco.mavidey.com.br/${item.url_image_request}`}
                            alt='user image'
                          />
                      }
                    </div>
                  }
                </>
              }
              <div className="solicitacaoFooter">
                <p style={{ color: '#2c2c2c' }}>Status: {item.status}</p>
                <div className="footerRow">
                  {/* <button className="delete" onClick={() => handleRemoveSolicitation(item)} disabled={loading}>
                    Remover
                  </button> */}
                  <select
                    onChange={e => setStatusSelected(e.target.value)}
                  >
                    <option value="">Novo status</option>
                    <option value="Aprovado">Aprovado</option>
                    <option value="Em Aberto">Em Aberto</option>
                    <option value="Em Andamento">Em Andamento</option>
                    <option value="Concluída">Concluída</option>
                    <option value="Cancelado">Cancelado</option>
                    <option value="Pendente">Pendente</option>
                  </select>
                  <button onClick={() => handleUpdateStatus(item)} disabled={loading}>
                    Atualizar
                  </button>
                </div>
              </div>

            </div>
          ))
        }

      </div>
    </ModalArea >
  )
}