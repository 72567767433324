import { useContext, useState } from 'react';
import { toast } from 'react-hot-toast';
import { AuthContext } from '../../contexts/auth';

import {
  Container
} from './styles';

export default function SignIn() {

  const { signIn, loadingAuth } = useContext(AuthContext)
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');

  const handleLogin = () => {
    if (!email || !pass) {
      toast.error('Informe suas credenciais de acesso para continuar!');
      return;
    }

    signIn(email, pass);
  }

  return (
    <Container>
      <img
        src={require('../../assets/logo.png')}
        alt='Câmara Osasco'
      />
      <div className="card">
        <input
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder='E-mail'
          type="email"
        />
        <input
          value={pass}
          onChange={e => setPass(e.target.value)}
          placeholder='Senha'
          type="password"
        />
        <button onClick={() => handleLogin()} disabled={loadingAuth}>
          {loadingAuth ? 'Carregando...' : 'Acessar'}
        </button>
      </div>
    </Container>
  )
}
