import { useEffect, useState } from "react";
import { Container } from "./styles";

import { SolicitacaoData } from "../../types/solicitacao";

import Chart from 'chart.js/auto';
import { CategoryScale } from "chart.js";
import { Doughnut } from "react-chartjs-2";

Chart.register(CategoryScale);

export default function Solicitacoes({ data }: { data: SolicitacaoData[] }) {

  const [tipos, setTipos] = useState<string[]>([]);
  const [dataPorTipo, setDataPorTipo] = useState<{ label: string; data: number }[]>([]);
  //
  const [status, setStatus] = useState<string[]>([]);
  const [dataPorStatus, setDataPorStatus] = useState<{ label: string; data: number }[]>([]);
  //
  const [partidos, setPartidos] = useState<string[]>([]);
  const [dataPorPartido, setDataPorPartido] = useState<{ label: string; data: number }[]>([]);
  //
  const [vereadores, setVereadores] = useState<string[]>([]);
  const [dataPorVereador, setDataPorVereador] = useState<{ label: string; data: number }[]>([]);

  // POR TIPO
  useEffect(() => {
    const tipos = data.reduce((acc: string[], { tipo }) => {
      if (!acc.includes(tipo)) {
        acc.push(tipo);
      }
      return acc;
    }, []);
    setTipos(tipos);
  }, [data]);

  useEffect(() => {
    const dataPorTipo = tipos.map((tipo) => {
      const dataC = data.filter((s) => s.tipo === tipo);
      return {
        label: tipo,
        data: dataC.length,
      };
    });
    setDataPorTipo(dataPorTipo);
  }, [data, tipos]);
  // POR TIPO

  // POR STATUS
  useEffect(() => {
    const status = data.reduce((acc: string[], { status }) => {
      if (!acc.includes(status)) {
        acc.push(status);
      }
      return acc;
    }, []);
    setStatus(status);
  }, [data]);

  useEffect(() => {
    const dataPorStatus = status.map((status) => {
      const dataC = data.filter((s) => s.status === status);
      return {
        label: status,
        data: dataC.length,
      };
    });
    setDataPorStatus(dataPorStatus);
  }, [data, status]);
  // POR STATUS

  // POR PARTIDO
  useEffect(() => {
    const partidos = data.reduce((acc: string[], { vereador: { partido } }) => {
      if (!acc.includes(partido)) {
        acc.push(partido);
      }
      return acc;
    }, []);
    setPartidos(partidos);
  }, [data]);

  useEffect(() => {
    const dataPorPartido = partidos.map((partido) => {
      const dataC = data.filter((s) => s.vereador.partido === partido);
      return {
        label: partido,
        data: dataC.length,
      };
    });
    setDataPorPartido(dataPorPartido);
  }, [data, partidos]);
  // POR PARTIDO

  // POR NOME VEREADOR
  useEffect(() => {
    const vereadores = data.reduce((acc: string[], { vereador: { nome } }) => {
      if (!acc.includes(nome)) {
        acc.push(nome);
      }
      return acc;
    }, []);
    setVereadores(vereadores);
  }, [data]);

  useEffect(() => {
    const dataPorVereador = vereadores.map((vereador) => {
      const dataC = data.filter((s) => s.vereador.nome === vereador);
      return {
        label: vereador,
        data: dataC.length,
      };
    });
    setDataPorVereador(dataPorVereador);
  }, [data, vereadores]);
  // POR NOME VEREADOR

  return (
    <>
      <Container>
        <h2>Solicitações</h2>

        <div className="cardsArea">

          <div className="cardItem">
            <h3>Solicitações por Tipo</h3>
            <Doughnut data={{
              labels: dataPorTipo.map(d => d.label),
              datasets: [{
                data: dataPorTipo.map(d => d.data),
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FF8A2A', '#4BC0C0'],
                hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FF8A2A', '#4BC0C0']
              }]
            }} />
          </div>

          <div className="cardItem">
            <h3>Solicitações por Status</h3>
            <Doughnut data={{
              labels: dataPorStatus.map(d => d.label),
              datasets: [{
                data: dataPorStatus.map(d => d.data),
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FF8A2A', '#4BC0C0'],
                hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FF8A2A', '#4BC0C0']
              }]
            }} />
          </div>

          <div className="cardItem">
            <h3>Solicitações por Partido</h3>
            <Doughnut data={{
              labels: dataPorPartido.map(d => d.label),
              datasets: [{
                data: dataPorPartido.map(d => d.data),
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FF8A2A', '#4BC0C0'],
                hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FF8A2A', '#4BC0C0']
              }]
            }} />
          </div>

          <div className="cardItem">
            <h3>Solicitações por Vereador</h3>
            <Doughnut data={{
              labels: dataPorVereador.map(d => d.label),
              datasets: [{
                data: dataPorVereador.map(d => d.data),
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FF8A2A', '#4BC0C0'],
                hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FF8A2A', '#4BC0C0']
              }]
            }} />
          </div>

        </div>

      </Container>
    </>
  )
}
