import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  padding: 0 4%;

  h2 {
    font-size: 25px;
    text-align: center;
    margin-bottom: 30px;
  }

  .vereadoresArea {
    max-width: 1200px;
    width: 100%;
    margin: 30px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
  }

  .card {
    max-width: 350px;
    width: 100%;
    padding: 10px;
    background: #fff;
    display: flex;
    align-items: center;
    box-shadow: 1px 1px 15px #eee;
  }

  img.photo {
    border: 2px solid #5b21b6;
    box-shadow: 1px solid #eee;
    border-radius: 4px;
    margin-right: 10px;
    min-width: 100px;
    max-width: 100px;
    width: 100%;
    align-self: flex-start;
  }

  button.buttonVereador {
    background: #fff;
    border: 1px solid #5b21b6;
    color: #5b21b6;
    border-radius: 3px;
    padding: 3px;
    transition: all ease 0.5s;
    margin-top: 10px;
    width: 125px;

    &:hover {
      background: #5b21b6;
      color: #fff;
      transition: all ease 0.5s;
    }
  }

  div.infoArea {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  div.areaPartido {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
  }

  div.areaSolicitacoes {
    margin: 10px 0;
    display: flex;
    align-items: center;
    align-self: flex-start;
    border-top: 1px solid #ddd;
    width: 100%;
    padding-top: 5px;
    gap: 15px;

    button {
      background: none;
      border: none;
      font-size: 14px;
      color: #5b21b6;
      display: flex;
      align-items: center;
      gap: 2px;

      svg {
        font-size: 16px;
      }
    }
  }

  div.buttonsArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;

    button {
      background: none;
      border: none;
      outline: none;

      svg {
        font-size: 18px;
        color: #4285f4;
      }

      svg.delete {
        font-size: 18px;
        color: #d84437;
      }
    }
  }
`;
