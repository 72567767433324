import { useContext, useState } from 'react';
import { toast } from 'react-hot-toast';
import { AuthContext } from '../../contexts/auth';

import {
  Container
} from './styles';

export default function LoginVereador() {

  const { signInVereador, loadingAuth } = useContext(AuthContext)

  const [number, setNumber] = useState('');

  const handleLogin = () => {
    if (!number) {
      toast.error('Informe suas credenciais de acesso para continuar!');
      return;
    }

    signInVereador(number);
  }

  return (
    <Container>
      <img
        src={require('../../assets/logo.png')}
        alt='Câmara Osasco'
      />
      <div className="card">
        <input
          value={number}
          onChange={e => setNumber(e.target.value)}
          placeholder='Matrícula'
          type="password"
        />
        <button onClick={() => handleLogin()} disabled={loadingAuth}>
          {loadingAuth ? 'Carregando...' : 'Acessar'}
        </button>
      </div>
    </Container>
  )
}
