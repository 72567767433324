import styled from 'styled-components';

type ButtonProps = {
  isActive: boolean;
};

export const Container = styled.main`
  width: 100%;
  padding: 0 4%;

  .navarea {
    max-width: 1200px;
    width: 100%;
    margin: 30px auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;

    .navitem {
      padding: 30px;
      background: #fff;
      border-radius: 5px;
      max-width: 300px;
      width: 100%;
      margin: 10px;
      display: flex;
      flex-direction: column;

      .row {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        svg {
          font-size: 50px;
          color: #5b21b6;
        }

        h5 {
          font-size: 32px;
        }
      }

      .reloadArea {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;

        button {
          border: none;
          background: none;
          color: #5b21b6;

          svg {
            font-size: 20px;
          }
        }
      }

      p {
        font-weight: bold;
        font-size: 14px;
        color: #999;
        margin-top: 15px;
      }
    }
  }
`;

export const ButtonNav = styled.button<ButtonProps>`
  padding: 5px 10px;
  border: 1px solid #5b21b6;
  border-radius: 4px;
  color: ${(props) => (props.isActive ? '#fff' : '#5b21b6')};
  font-weight: bold;
  margin-top: 15px;
  background: ${(props) => (props.isActive ? '#5b21b6' : '#fff')};
  transition: all ease 0.4s;

  &:hover {
    background: #5b21b6;
    color: #fff;
    transition: all ease 0.4s;
  }
`;
