import { useState, createContext, useEffect, ReactNode } from "react";
import firebase from '../services/firebaseConnect';
import { toast } from "react-hot-toast";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Cookies from 'js-cookie';

type AuthContextData = {
  user: UserProps | null;
  signed: boolean;
  loadingAuth: boolean;
  loading: boolean;
  signIn: (email: string, pass: string) => Promise<void>;
  signInVereador: (number: string) => Promise<void>;
  signOut: () => Promise<void>;
  signOutVereador: () => Promise<void>;
};

type UserProps = {
  uid: string;
  email: string;
  permission?: string;
  token?: string;
};

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthContext = createContext({} as AuthContextData);

export function AuthProvider({ children }: AuthProviderProps) {

  const navigate = useNavigate();

  const [user, setUser] = useState<UserProps | null>(null);
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const signed = !!user;

  useEffect(() => {
    (async () => {

      const userStorage = await localStorage.getItem('@camaraosasco:auth');

      if (userStorage) {
        setUser(JSON.parse(userStorage));
        setLoading(false);
      }

      setLoading(false);

    })();
  }, []);

  const signInVereador = async (number: string) => {
    setLoadingAuth(true)
    try {


      const collectionRef = firebase.firestore().collection('vereadores');
      const query = collectionRef.where('numMatricula', '==', number);

      query.get().then(document => {
        if (document.empty) {
          toast.error('Certifique-se de ter esse número de matrícula cadastrado para poder acessar!');
          return;
        }

        document.forEach(doc => {
          const data = {
            uid: number,
            email: doc.data().email
          };

          setUser(data)
          storageUser(data);
          navigate('/dashboard-vereador');
        })

      })

    }
    catch (error) {
      alert('Não foi possível acessar, tente novamente!');
    }
    finally {
      setLoadingAuth(false);
    }

  }

  const signIn = async (email: string, pass: string) => {

    setLoadingAuth(true)

    try {

      const { data } = await axios.post("https://camaraosasco.mavidey.com.br/auth/login", {
        email,
        password: pass
      },
        { withCredentials: true }
      )


      if (!data) {
        toast.error('Aconteceu um erro inesperado, tente novamente!');
        return;
      }

      if (data.permission != "ADMIN") {
        toast.error('Você não tem permissão para acessar essa página!');
        return;
      }

      const user = {
        uid: data.uuid,
        email: data.email,
        token: data.token,
        permission: data.permission
      }

      // document.cookie = `token=${data.token}`;
      setUser(user)
      storageUser(user);
      navigate('/dashboard');
    }
    catch (error: any) {
      if (!error.response) {
        return;
      }

      const messages = error.response.data.error;
      console.log(messages)
      if (Array.isArray(error.response.data.error)) {
        const messagesAllString = messages.join('\n');
        toast.error(messagesAllString);
        return;
      }

      toast.error(messages)
    }
    finally {
      setLoadingAuth(false);
    }
  }

  const storageUser = (data: UserProps) => {
    localStorage.setItem('@camaraosasco:auth', JSON.stringify(data));
  }

  const signOut = async () => {

    setUser(null);
    Cookies.remove('token');
    storageUser({ uid: '', email: '' });
    navigate('/');

  }

  const signOutVereador = async () => {
    // await firebase.auth().signOut()
    //   .then(async () => {
    //     await localStorage.removeItem('@camaraosasco:auth');
    //     navigate('/login-vereador');
    //   })
  }

  return (
    <AuthContext.Provider value={{
      user,
      signed,
      loadingAuth,
      loading,
      signIn,
      signInVereador,
      signOut,
      signOutVereador
    }}>
      {children}
    </AuthContext.Provider>
  )

}
