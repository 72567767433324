import {
  Container
} from './styles';

export default function PrivacyPolicy() {
  return (
    <Container>
      <h2>Política de Privacidade</h2>
      <p>
        A sua privacidade é importante para nós. É política do App da Câmara Municipal de Osasco respeitar a sua privacidade em relação a qualquer informação sua que possamos coletar no App da Câmara Municipal de Osasco.
        <br /><br />
        Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos coletando e como será usado.
        <br /><br />
        Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado e gerar indicadores de qualidade dos serviços prestados. Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis ​​para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados.
        <br /><br />
        Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei.
        <br /><br />
        O nosso App pode ter links para apps ou sites externos que não são operados por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas desses sites e não podemos aceitar responsabilidade por suas respectivas políticas de privacidade.
        <br /><br />
        Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados.
        <br /><br />
        Mediante solicitação do usuário, a Câmara Municipal de Osasco apagará sua conta e dados pessoais.
        <br /><br />
        O uso continuado de nosso App será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais, entre em contato conosco.
      </p>
    </Container>
  )
}