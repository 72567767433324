import styled from 'styled-components';

export const ModalArea = styled.div`
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  padding: 4%;
  display: flex;
  align-items: center;
  justify-content: center;

  button.closeModal {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    border: none;
    height: 32px;
    width: 32px;
    font-size: 32px;
    position: absolute;
    top: 3%;
    right: 3%;
    color: #fff;
  }

  .modalContent {
    max-width: 700px;
    width: 100%;
    height: 100vh;
    padding: 30px;
    background: #fff;
    border-radius: 5px;
    overflow: auto;
  }

  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  h2 {
    margin: 10px 0 20px 0;
  }

  .solicitacaoItem {
    padding: 10px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 1px 1px 15px #ddd;
    margin-bottom: 15px;
  }

  .solicitacaoHeader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #eee;
    margin-bottom: 15px;
    padding: 5px 0;
  }

  .solicitacaoFooter {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    border-top: 2px solid #eee;
    margin-top: 15px;
    padding: 5px 0;

    p {
      border-radius: 30px;
      border: 1px solid #5b21b6;
      padding: 5px 10px;
      color: #5b21b6;
      font-weight: bold;
    }

    .footerRow {
      display: flex;
      align-items: center;
      gap: 10px;

      button {
        background: #5b21b6;
        color: #fff;
        border: none;
        padding: 5px 10px;
        border-radius: 2px;
      }
      button.delete {
        background: #dc3545;
        color: #fff;
        border: none;
        padding: 5px 10px;
        border-radius: 2px;
      }
    }
  }

  select {
    max-width: 150px;
    background: #f4f4f4;
    border: 1px solid #999;
    box-shadow: 1px 1px 15px #eee;
    border-radius: 4px;
    width: 100%;
    padding: 5px 10px;
    margin: 10px 0;
    outline: none;
  }
`;

export const Container = styled.div`
  width: 100%;
  padding: 30px 5px;
  box-shadow: 1px 1px 15px #ddd;
  margin: 30px auto;

  textarea {
    height: 100px;
    background: #f4f4f4;
    border: 1px solid #999;
    box-shadow: 1px 1px 15px #eee;
    border-radius: 4px;
    width: 100%;
    padding: 5px 10px;
    margin: 10px 0;
  }

  input,
  select {
    height: 40px;
    background: #f4f4f4;
    border: 1px solid #999;
    box-shadow: 1px 1px 15px #eee;
    border-radius: 4px;
    width: 100%;
    padding: 5px 10px;
    margin: 10px 0;
  }

  button {
    background: #5b21b6;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 2px;
  }

  button.send {
    width: 100%;
    height: 40px;
    background: #5b21b6;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 2px;
    font-weight: bold;
    margin-top: 30px;
  }

  div.areaArray {
    margin-bottom: 10px;
    background: #fff;
    border-radius: 3px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
  }

  div.areaItemArray {
    margin-bottom: 10px;
    background: #f4f4f4;
    border-radius: 3px;
    padding: 2px 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2px;
    background: #f4f4f4;
    border: 1px solid #5b21b6;
    border-radius: 3px;
  }
  div.areaItemArray button {
    border: none;
    outline: none;
    background: none;
    color: #999;
  }

  img {
    border: 2px solid #5b21b6;
    box-shadow: 1px solid #eee;
    border-radius: 4px;
  }
`;
