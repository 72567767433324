import styled from 'styled-components';

export const Container = styled.div`
  max-width: 700px;
  width: 100%;
  padding: 30px;
  box-shadow: 1px 1px 15px #ddd;
  margin: 30px auto;

  p {
    font-weight: bold;
    font-size: 12px;
    color: #999;
    margin: 10px 0;
    text-align: center;
  }

  textarea {
    height: 100px;
    background: #f4f4f4;
    border: 1px solid #999;
    box-shadow: 1px 1px 15px #eee;
    border-radius: 4px;
    width: 100%;
    padding: 5px 10px;
    margin: 10px 0;
  }

  input,
  select {
    height: 40px;
    background: #f4f4f4;
    border: 1px solid #999;
    box-shadow: 1px 1px 15px #eee;
    border-radius: 4px;
    width: 100%;
    padding: 5px 10px;
    margin: 10px 0;
  }

  button {
    background: #5b21b6;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 2px;
  }

  button.send {
    width: 100%;
    height: 40px;
    background: #5b21b6;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 2px;
    font-weight: bold;
    margin-top: 10px;
  }

  div.areaArray {
    margin-bottom: 10px;
    background: #fff;
    border-radius: 3px;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
  }

  div.areaItemArray {
    margin-bottom: 10px;
    background: #f4f4f4;
    border-radius: 3px;
    padding: 2px 10px;
    display: flex;
    align-items: center;
    gap: 2px;
    background: #f4f4f4;
    border: 1px solid #5b21b6;
    border-radius: 3px;
  }
  div.areaItemArray button {
    border: none;
    outline: none;
    background: none;
    color: #999;
  }

  img {
    border: 2px solid #5b21b6;
    box-shadow: 1px solid #eee;
    border-radius: 4px;
  }
`;
