import { useState } from "react";
import { toast } from "react-hot-toast";
import { Container } from "./styles";

import firebase from '../../services/firebaseConnect';

type LiveProps = {
  url: string;
  getLives: () => void;
}

export default function Lives({ url, getLives }: LiveProps) {

  const [liveUrl, setLiveUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const handleAddLive = () => {
    if (!liveUrl) {
      toast.error('Informe um link para a live!');
      return;
    }

    addLive();
  }

  const addLive = async () => {
    if (!liveUrl) return;

    setLoading(true);
    try {

      await firebase.firestore().collection('lives').doc('current_live').set({
        url: liveUrl
      })
        .then(() => {
          toast.success('Live adicionada com sucesso!');
          getLives();
        })

    }
    catch (error) {
      // console.log('LIVE ERROR: ', error);
    }
    finally {
      setLoading(false);
    }
  }

  const removeLive = async () => {
    setLoading(true);
    try {
      await firebase.firestore().collection('lives').doc('current_live').update({
        url: ''
      })
        .then(() => {
          toast.success('Live removida com sucesso!');
          getLives();
          // router.reload();
        })
    }
    catch (error) {
      // console.log('REMOVE LIVE ERROR: ', error);
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      {
        url ?
          <>
            <button
              className="send"
              disabled={loading}
              onClick={() => removeLive()}
            >{loading ? 'Carregando...' : 'Remover live'}</button>
            <p>
              Já existe uma live salva, faça a remoção dela para adicionar uma nova.
            </p>
          </>
          :
          <>
            <input type='url'
              value={liveUrl}
              onChange={e => setLiveUrl(e.target.value)}
              placeholder="Adicione o link da live"
            />
            <button
              className="send"
              disabled={loading}
              onClick={() => handleAddLive()}
            >{loading ? 'Carregando...' : 'Adicionar live'}</button>
            <p>
              Ao adicionar um link para a live, ela será disponibilizada na seção ao vivo na tela inicial do aplicativo.
            </p>
          </>
      }
    </Container>
  )
}
