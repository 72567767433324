import styled from 'styled-components';

export const Container = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4%;

  h2 {
    text-align: center;
    margin-bottom: 30px;
  }

  p {
    max-width: 750px;
    font-size: 1.1rem;
  }
`;
