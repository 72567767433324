import {
  FiRefreshCw,
  FiX
} from 'react-icons/fi';

import { ModalArea, Container } from "./styles";

import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

import firebase from '../../services/firebaseConnect';
import { VereadorData } from '../../types/vereador';
import { PARTIDOS_DATA } from '../AddVereador';
import axios from 'axios';

type ALL_PROPS = {
  infos: VereadorData;
  partidos: PARTIDOS_DATA[];
  closeModal: () => void;
}

export default function ModalEditarVereadores({ infos, partidos, closeModal }: any) {

  // const [vereadorInfo, setVereadorInfo] = useState(infos);
  const [loading, setLoading] = useState(false);

  // INPUTS
  const [biografria, setBiografia] = useState(infos.biography);
  const [novaComissao, setNovaComissao] = useState('');
  const [comissoes, setComissoes] = useState<string[] | []>(infos.comissions);
  const [email, setEmail] = useState(infos.email);
  const [endereco, setEndereco] = useState(infos.address);
  const [novaFrente, setNovaFrente] = useState('');
  const [frentes, setFrentes] = useState<string[] | []>(infos.parliamentary_front);
  const [grau, setGrau] = useState(infos.level_education);
  const [novaLegislatura, setNovaLegislatura] = useState('');
  const [legislaturas, setLegislaturas] = useState<string[] | []>(infos.legislatures);
  // const [mesa, setMesa] = useState(infos.mesa_diretora);
  const [nascimento, setNascimento] = useState(infos.birth_date);
  const [naturalidade, setNaturalidade] = useState(infos.naturalness);
  const [nome, setNome] = useState(infos.name);
  const [ocupacao, setOcupacao] = useState(infos.ocupation);
  const [partido, setPartido] = useState(infos.name_party);
  // const [partidoLogo, setPartidoLogo] = useState('');
  const [facebook, setFacebook] = useState(infos.facebook);
  const [instagram, setInstagram] = useState(infos.instagram);
  // const [linkRedeSocial, setLinkRedeSocial] = useState('');
  // const [redesSociais, setRedesSociais] = useState<>([]);
  const [telefone, setTelefone] = useState(infos.telephone);
  const [novoTitulo, setNovoTitulo] = useState('');
  const [titulos, setTitulos] = useState<string[] | []>(infos.titles);
  // const [whatsapp, setWhatsapp] = useState(infos.whatsapp);
  //
  const [allPartidos, setAllPartidos] = useState<PARTIDOS_DATA[] | []>(partidos);

  const [imagem, setImagem] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState('')

  //
  const [numMatricula, setNumMatricula] = useState(infos.registration);
  // INPUTS

  const handleAddComissoes = () => {
    if (!novaComissao) {
      toast.error('Adicione uma comissão!');
      return;
    }

    setComissoes(prev => [...prev, novaComissao]);
    setNovaComissao('');
  }

  const handleAddFrentes = () => {
    if (!novaFrente) {
      toast.error('Adicione uma Frente!');
      return;
    }

    setFrentes(prev => [...prev, novaFrente]);
    setNovaFrente('');
  }

  const handleAddLegislaturas = () => {
    if (!novaLegislatura) {
      toast.error('Adicione uma Frente!');
      return;
    }

    setLegislaturas(prev => [...prev, novaLegislatura]);
    setNovaLegislatura('');
  }

  const handleAddTitulo = () => {
    if (!novoTitulo) {
      toast.error('Adicione um título!');
      return;
    }

    setTitulos(prev => [...prev, novoTitulo]);
    setNovoTitulo('');
  }

  const removeComissao = (comissao: string) => {
    const filtered = comissoes.filter(item => item !== comissao);
    setComissoes(filtered);
  }

  const removeFrente = (frente: string) => {
    const filtered = frentes.filter(item => item !== frente);
    setFrentes(filtered);
  }

  const removeLegislatura = (legislatura: string) => {
    const filtered = legislaturas.filter(item => item !== legislatura);
    setLegislaturas(filtered);
  }

  const removeTitulo = (titulo: string) => {
    const filtered = titulos.filter(item => item !== titulo);
    setTitulos(filtered);
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const newSelectedFile = e.target.files[0];
      setSelectedFile(newSelectedFile);

      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(newSelectedFile);

      return;
    }

    toast.error('Nenhuma imagem selecionado!');
  };

  const getPartidos = async () => {
    const response = await axios.get('https://camaraosasco.mavidey.com.br/government_party');
    const data = response.data;
    setAllPartidos(data)
  }

  const handleEditPerson = async () => {

    const partidosEscolhido: any = allPartidos.filter((item: any) => item.name === partido);

    if (!nome || !email || !nascimento || !partido || !ocupacao || !telefone || !numMatricula || !biografria || !comissoes || !endereco || !frentes || !grau || !legislaturas || !naturalidade || !titulos || !facebook || !instagram || !partidosEscolhido) {
      toast.error('Preencha os campos para continuar!');
      return;
    }

    setLoading(true);

    try {
      const data = {
        biography: biografria,
        commision: comissoes,
        email: email,
        address: endereco,
        parliamentary_front: frentes,
        level_education: grau,
        legislature: legislaturas,
        birth_date: nascimento,
        naturalness: naturalidade,
        name: nome,
        ocupation: ocupacao,
        id_government_party: partidosEscolhido[0].id_government_party,
        facebook: facebook,
        instagram: instagram,
        telephone: telefone,
        registration: numMatricula,
        title: titulos,
        id_politician: infos.id_politician
      }

      await axios.put(`https://camaraosasco.mavidey.com.br/politician/update`, data, {
        withCredentials: true
      })


      if (selectedFile) {
        const formData = new FormData();
        formData.append('profile', selectedFile);
        formData.append('id_politician', infos.id_politician);

        await axios.post('https://camaraosasco.mavidey.com.br/politician/upload-image', formData, {
          withCredentials: true
        })
      }


      toast.success('Vereador atualizado com sucesso!');
      window.location.reload();

    }
    catch (error) {
      console.log(error)
    }
    finally {
      setLoading(false);
    }
  }


  useEffect(() => {
    getPartidos();
  }, [])

  return (
    <ModalArea>
      <button className="closeModal" onClick={() => closeModal()}>
        <FiX />
      </button>
      <div className="modalContent">

        <Container>

          {
            (previewUrl === '' && infos.image) &&
            <div>
              <p style={{ fontWeight: 'bold' }}>Foto atual:</p>
              <img
                src={infos.image?.includes("https://firebasestorage") ? infos.image : `https://camaraosasco.mavidey.com.br/${infos.image}`}
                style={{ width: 100, height: 100 }}
              />
            </div>
          }

          {
            previewUrl &&
            <img
              src={previewUrl}
              style={{ width: 100, height: 100 }}
            />

          }

          {
            <input type="file"
              onChange={handleFileChange}
              placeholder="Foto"
            />
          }

          <textarea
            value={biografria}
            onChange={e => setBiografia(e.target.value)}
            placeholder="Biografia"
          ></textarea>

          <input type="text"
            value={novaComissao}
            onChange={e => setNovaComissao(e.target.value)}
            placeholder="Comissões"
          />

          <button onClick={() => handleAddComissoes()}>Adicionar Comissão</button>

          <div className='areaArray'>
            {
              comissoes.length > 0 &&
              comissoes.map(comissao => (
                <div key={comissao} className="areaItemArray">
                  <p>{comissao}</p>
                  <button onClick={() => removeComissao(comissao)}>X</button>
                </div>
              ))
            }
          </div>

          <input type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="E-mail"
          />


          <input type="text"
            value={endereco}
            onChange={e => setEndereco(e.target.value)}
            placeholder="Endereço"
          />



          <input type="text"
            value={novaFrente}
            onChange={e => setNovaFrente(e.target.value)}
            placeholder="Frentes Parlamentares"
          />

          <button onClick={() => handleAddFrentes()}>Adicionar Frente Parlamentar</button>

          <div className='areaArray'>
            {
              frentes.length > 0 &&
              frentes.map(frente => (
                <div key={frente} className="areaItemArray">
                  <p>{frente}</p>
                  <button onClick={() => removeFrente(frente)}>X</button>
                </div>
              ))
            }
          </div>



          <input type="text"
            value={grau}
            onChange={e => setGrau(e.target.value)}
            placeholder="Grau instrução"
          />

          <input type="text"
            value={novaLegislatura}
            onChange={e => setNovaLegislatura(e.target.value)}
            placeholder="Legislaturas"
          />
          <button onClick={() => handleAddLegislaturas()}>Adicionar Legislatura</button>

          <div className='areaArray'>
            {
              legislaturas.length > 0 &&
              legislaturas.map(legislatura => (
                <div key={legislatura} className="areaItemArray">
                  <p>{legislatura}</p>
                  <button onClick={() => removeLegislatura(legislatura)}>X</button>
                </div>
              ))
            }
          </div>

          <label htmlFor="data">Data nascimento</label>
          <input type="date"
            value={nascimento}
            onChange={e => setNascimento(e.target.value)}
            placeholder="Nascimento (00/00/0000)"
          />

          <input type="text"
            value={naturalidade}
            onChange={e => setNaturalidade(e.target.value)}
            placeholder="Naturalidade"
          />

          <input type="text"
            value={nome}
            onChange={e => setNome(e.target.value)}
            placeholder="Nome"
          />




          <input type="text"
            value={ocupacao}
            onChange={e => setOcupacao(e.target.value)}
            placeholder="Ocupação"
          />



          <div style={{ display: 'flex', alignItems: 'center' }}>
            <select onChange={e => setPartido(e.target.value)}>
              <option value=''>Partido Atual: {infos.name_party}</option>
              {
                allPartidos.map((partido: any) => (
                  <option key={partido.name} value={partido.name}>{partido.name}</option>
                ))
              }
            </select>
            <button onClick={() => getPartidos()} style={{ height: 40, marginLeft: 5, borderRadius: 5 }}>
              <FiRefreshCw />
            </button>
          </div>



          <input type="url"
            value={facebook}
            onChange={e => setFacebook(e.target.value)}
            placeholder="Facebook"
          />
          <input type="url"
            value={instagram}
            onChange={e => setInstagram(e.target.value)}
            placeholder="Instagram"
          />

          <input type="text"
            value={telefone}
            onChange={e => setTelefone(e.target.value)}
            placeholder="Telefone"
          />

          <input type="text"
            value={novoTitulo}
            onChange={e => setNovoTitulo(e.target.value)}
            placeholder="Titulo"
          />
          <button onClick={() => handleAddTitulo()}>Adicionar Título</button>

          <div className='areaArray'>
            {
              titulos.length > 0 &&
              titulos.map(titulo => (
                <div key={titulo} className="areaItemArray">
                  <p>{titulo}</p>
                  <button onClick={() => removeTitulo(titulo)}>X</button>
                </div>
              ))
            }
          </div>

          <input type="text"
            value={numMatricula}
            onChange={e => setNumMatricula(e.target.value)}
            placeholder="Número da Matrícula"
          />

          <button className="send" onClick={() => handleEditPerson()} disabled={loading}>
            {loading ? 'Carregando...' : 'Atualizar Vereador'}
          </button>
        </Container>

      </div>
    </ModalArea >
  )
}