import { useContext } from 'react';
import { AuthContext } from '../../contexts/auth';
import {
  HeaderContainer
} from './styles';

export default function Header() {

  const { signOut, signOutVereador, user } = useContext(AuthContext);

  return (
    <HeaderContainer>
      <img
        src={require('../../assets/logo.png')}
        alt='Câmara Osasco'
      />
      <button onClick={signOut}>
        Sair da conta
      </button>
    </HeaderContainer>
  );
}
