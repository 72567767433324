import firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyB8fhzB3qFh3AsB7t0TIhwYKunGBQWyQ60',
  authDomain: 'teste-osasco.firebaseapp.com',
  projectId: 'teste-osasco',
  storageBucket: 'teste-osasco.appspot.com',
  messagingSenderId: '589797041027',
  appId: '1:589797041027:web:0817b3d81f3c4dde3f0cb6',
  measurementId: 'G-W8HRK26MZC',
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export default firebase;
