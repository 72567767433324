import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  padding: 0 4% 4% 4%;

  h2 {
    font-size: 25px;
    text-align: center;
    margin-bottom: 30px;
  }

  .vereadoresArea {
    max-width: 1200px;
    width: 100%;
    margin: 30px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
  }

  .card {
    max-width: 350px;
    width: 100%;
    padding: 10px;
    background: #fff;
    display: flex;
    align-items: center;
    box-shadow: 1px 1px 15px #eee;
  }

  img.photo {
    border: 2px solid #5b21b6;
    box-shadow: 1px solid #eee;
    border-radius: 4px;
    margin-right: 10px;
    min-width: 100px;
    max-width: 100px;
    width: 100%;
  }

  div.infoArea {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  div.areaPartido {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
  }

  div.buttonsArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;

    button {
      background: none;
      border: none;
      outline: none;

      svg {
        font-size: 18px;
        color: #4285f4;
      }

      svg.delete {
        font-size: 18px;
        color: #d84437;
      }
    }
  }

  .cardsArea {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
  }

  .cardItem {
    max-width: 600px;
    width: 100%;
    background: #fff;
    box-shadow: 1px 1px 15px #eee;
    padding: 10px 10px 30px 10px;
    border-radius: 5px;

    h3 {
      text-align: center;
      margin-bottom: 10px;
    }
  }
`;
