import styled from 'styled-components';

export const HeaderContainer = styled.header`
  width: 100%;
  padding: 1% 4%;
  background: #f4f4f4;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 75px;
    height: 75px;
    border-radius: 37.5px;
    margin-bottom: 10px;
  }

  button {
    background: none;
    border: none;
    border-bottom: 1px solid #5b21b6;
    color: #5b21b6;
  }
`;
