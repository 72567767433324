import {
  FiEdit,
  FiTrash2,
  FiX,
  FiEye
} from 'react-icons/fi';

import firebase from '../../services/firebaseConnect';

import { Container } from "./styles";

import { VereadorData } from '../../types/vereador';
import { SolicitacaoData, Vereador } from '../../types/solicitacao';
import { useState } from "react";
import ModalSolicitacoes from "../ModalSolicitacoes";
import { toast } from "react-hot-toast";
import ModalEditarVereadores from '../ModalEditarVereadores';
import { PARTIDOS_DATA } from '../AddVereador';
import AddAccess from '../AddAccess';
import axios from 'axios';
// import Modal from "../Modal";

type ALL_TYPES = {
  data: VereadorData[];
  dataSolicitacoes: SolicitacaoData[];
  removeCount: () => void;
  partidos: PARTIDOS_DATA[];
}

export default function Vereadores({ data, dataSolicitacoes, removeCount, partidos }: ALL_TYPES) {

  // const [personEdit, setPersonEdit] = useState<VereadorData | null>(null);
  const [loading, setLoading] = useState(false);
  const [allVereadores, setAllVereadores] = useState<VereadorData[] | []>(data);

  const [viewRequests, setViewRequests] = useState<SolicitacaoData[] | null>(null);
  const [vereadorEdit, setVereadorEdit] = useState<VereadorData | null>(null);
  const [vereadorAcessores, setVereadorAcessores] = useState<VereadorData | null>(null);

  const handlePersonDelete = async (vereadorId: string, refImage: string) => {


    setLoading(true);

    try {

      const response = await axios.delete(`https://camaraosasco.mavidey.com.br/politician/${vereadorId}`, {
        withCredentials: true
      });

      if (response.status === 200) {
        const filtered = allVereadores.filter((item: any) => item.id_politician !== vereadorId);
        setAllVereadores(filtered)
        removeCount();
        toast.success('Vereador removido com sucesso!');
      }

    }
    catch (error) {
      console.log(error)
    }
    finally {
      setLoading(false);
    }


  }

  return (
    <>
      {/* {
        personEdit !== null &&
        <Modal vereador={personEdit} />
      } */}

      {
        viewRequests !== null &&
        <ModalSolicitacoes closeModal={() => setViewRequests(null)} solicitacoes={viewRequests} />
      }

      {
        vereadorEdit !== null &&
        <ModalEditarVereadores closeModal={() => setVereadorEdit(null)} partidos={partidos} infos={vereadorEdit} />
      }

      {
        vereadorAcessores !== null ?
          <AddAccess data={vereadorAcessores} closeAcessos={() => setVereadorAcessores(null)} />
          :
          <Container>
            <h2>Gerenciar Vereadores</h2>

            <div className="vereadoresArea">
              {
                allVereadores.length > 0 &&
                allVereadores.map((vereador: any) => (
                  <div key={vereador.id_politician} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: '#fff', maxWidth: 350, width: '100%', minHeight: 175, paddingBottom: 10 }}>
                    <div className="card" >
                      <img src={vereador.image?.includes("https://firebasestorage") ? vereador.image : `https://camaraosasco.mavidey.com.br/${vereador.image}`} alt={vereador.name} width={100} height={100} className='photo' />
                      <div className="infoArea">
                        <h3>{vereador.name}</h3>
                        <div className="areaPartido">
                          <img src={vereador.government_party_image?.includes("https://firebasestorage") ? vereador.government_party_image : `https://camaraosasco.mavidey.com.br/${vereador.government_party_image}`} alt={vereador.name_party} width={100} height={40} />
                          <div className="buttonsArea">
                            {/* <button onClick={() => { }}>
                            <FiEdit />
                          </button> */}
                            {/* <button onClick={() => handlePersonDelete(vereador.id!, vereador.nome)} disabled={loading}>
                            <FiTrash2 className="delete" />
                          </button> */}
                          </div>
                        </div>
                        <div className="areaSolicitacoes">
                          <p>
                            Solicitações: <b>{
                              dataSolicitacoes.filter((item: any) => item.politician_name === vereador.name).length
                            }</b>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div style={{ paddingLeft: 7, paddingRight: 7 }}>
                      <div style={{ maxWidth: 275, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: 'auto' }}>
                        <button className='buttonVereador' onClick={() => {
                          const currentSolicitacoes = dataSolicitacoes.filter((item: any) => item.politician_name === vereador.name).length;
                          if (currentSolicitacoes <= 0) {
                            toast.error('Este vereador não tem nenhuma solicitação!');
                            return;
                          }

                          setViewRequests(dataSolicitacoes.filter((item: any) => item.politician_name === vereador.name))
                        }}>
                          Ver Solicitações
                        </button>
                        <button className='buttonVereador' onClick={() => setVereadorEdit(vereador)}>Editar Vereador</button>
                      </div>

                      <div style={{ maxWidth: 275, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: 'auto' }}>
                        <button className="buttonVereador" onClick={() => setVereadorAcessores(vereador)} disabled={loading}>
                          Gerenciar acessos
                        </button>
                        <button className='buttonVereador' onClick={() => handlePersonDelete(vereador.id_politician!, vereador.name)} disabled={loading}>
                          Deletar Vereador
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </Container>
      }

    </>
  )
}
