import { useState } from 'react';
import { toast } from 'react-hot-toast';
import firebase from '../../services/firebaseConnect';
import { v4 as uuidV4 } from 'uuid';

import {
  Container, VideoItem
} from './styles';

type TitleScreenProps = {
  data: TITLE_DATA[],
}

export type TITLE_DATA = {
  id?: string;
  link?: string;
  thumbnail?: string;
  title?: string;
}

export default function AddVideosTitle({ data }: TitleScreenProps) {

  const [allTitles, setAllTitles] = useState<TITLE_DATA[] | []>(data)

  const [titulosIndividuais, setTitulosIndividuais] = useState({});
  // const [image, setImage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChangeTitulo = (videoId: string, newTitle: string) => {
    setTitulosIndividuais((prevState) => ({
      ...prevState,
      [videoId]: newTitle,
    }));
  };

  const handleAddTitle = (video: TITLE_DATA) => {
    // @ts-ignore
    const currentTitle = titulosIndividuais[video?.id!]

    firebase.firestore().collection('videosYT').doc(video?.id!).set({
      title: currentTitle
    })
      .then(async () => {

        const filtered = await allTitles.filter(item => item.id !== video.id);
        video.title = currentTitle;
        setAllTitles([video, ...filtered]);
        toast.success('Título atualizado com sucesso!');
        // setObservation('');

      })
  }

  return (
    <Container>

      {
        allTitles?.map(item => (
          <VideoItem key={item.id}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 4 }}>
              <img src={item.thumbnail} alt={item.thumbnail} />
              <a href={item.link} target='_blank' style={{ color: '#5b21b6', fontWeight: 'bold' }} rel="noreferrer">Visualizar</a>
            </div>
            <div className="areaInput">
              <input type='text'
                // @ts-ignore
                value={titulosIndividuais[item.id!] || ''}
                onChange={e => handleChangeTitulo(item.id!, e.target.value)}
                placeholder={item.title || 'Título do vídeo'}
              />
              <button className="send" onClick={() => handleAddTitle(item)} disabled={loading}>
                {loading ? 'Carregando...' : 'Salvar título'}
              </button>
            </div>
          </VideoItem>
        ))
      }

      {/* <input type='url'
        value={nome}
        onChange={e => setNome(e.target.value)}
        placeholder="Título do vídeo"
      />

      <button className="send" onClick={() => handleAddTitle()} disabled={loading}>
        {loading ? 'Carregando...' : 'Adicionar Thumb'}
      </button> */}
    </Container>
  )

}
