import styled from 'styled-components';

export const Container = styled.main`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4%;

  img {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    margin-bottom: 30px;
  }

  .card {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 400px;
    width: 100%;
    background: #fff;
    border-radius: 5px;
    padding: 30px;

    input {
      width: 100%;
      padding: 5px 10px;
      background: #f4f4f4;
      border: none;
      outline: none;
      height: 50px;
      margin: 5px 0;
      font-size: 16px;
      border-radius: 5px;
      color: #2c2c2c;
    }

    button {
      width: 100%;
      height: 50px;
      background: #5b21b6;
      color: #fff;
      border: none;
      font-size: 20px;
      font-weight: 500;
      border-radius: 5px;
      margin-top: 10px;
    }
  }
`;
