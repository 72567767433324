import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './styles.module.css';
import axios from 'axios';
import toast from 'react-hot-toast';

export const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const location = useLocation();

    const handlePasswordChange = (e: any) => {
        setNewPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e: any) => {
        setConfirmPassword(e.target.value);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        const token = new URLSearchParams(location.search).get('token');

        try {

            const response = await axios.post('https://camaraosasco.mavidey.com.br/auth/reset-password', {
                forgotToken: token,
                newPassword,
                confirmPassword
            });

            toast.success('Senha resetada com sucesso!');
            setNewPassword('');
            setConfirmPassword('');
            
        } catch (error: any) {
            setError(error.response.data.error)
        }
    };

    return (
        <div className={styles.body}>
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.formGroup}>
                    <label htmlFor="newPassword">Nova senha</label>
                    <input
                        type="password"
                        id="newPassword"
                        value={newPassword}
                        onChange={handlePasswordChange}
                        required
                    />
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="confirmPassword">Confirme a nova senha</label>
                    <input
                        type="password"
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        required
                    />
                </div>
                {error && <p className={styles.error}>{error}</p>}
                <button type="submit" className={styles.button}>Resetar a nova senha</button>
            </form>
        </div>
    );
};
