import { VereadorData } from '../../types/vereador';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import firebase from '../../services/firebaseConnect';

import {
  Container
} from './styles';

import {
  FiRefreshCw
} from 'react-icons/fi';
import axios from 'axios';

export type PARTIDOS_DATA = {
  id?: string;
  nome: string;
  link: string;
};

type AddVereadorProps = {
  addCount: () => void;
  partidos: any[]
}

export default function AddVereador({ addCount, partidos }: AddVereadorProps) {

  const [biografria, setBiografia] = useState('');
  const [novaComissao, setNovaComissao] = useState('');
  const [comissoes, setComissoes] = useState<string[] | []>([]);
  const [email, setEmail] = useState('');
  const [endereco, setEndereco] = useState('');
  const [novaFrente, setNovaFrente] = useState('');
  const [frentes, setFrentes] = useState<string[] | []>([]);
  const [grau, setGrau] = useState('');
  const [novaLegislatura, setNovaLegislatura] = useState('');
  const [legislaturas, setLegislaturas] = useState<string[] | []>([]);
  const [mesa, setMesa] = useState('');
  const [nascimento, setNascimento] = useState('');
  const [naturalidade, setNaturalidade] = useState('');
  const [nome, setNome] = useState('');
  const [ocupacao, setOcupacao] = useState('');
  const [partido, setPartido] = useState('');
  // const [partidoLogo, setPartidoLogo] = useState('');
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  // const [linkRedeSocial, setLinkRedeSocial] = useState('');
  // const [redesSociais, setRedesSociais] = useState<>([]);
  const [telefone, setTelefone] = useState('');
  const [novoTitulo, setNovoTitulo] = useState('');
  const [titulos, setTitulos] = useState<string[] | []>([]);
  const [whatsapp, setWhatsapp] = useState('');
  //
  const [allPartidos, setAllPartidos] = useState<any[] | []>(partidos);

  const [imagem, setImagem] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState('')
  //
  const [numMatricula, setNumMatricula] = useState('');

  const [loading, setLoading] = useState(false);

  const handleAddComissoes = () => {
    if (!novaComissao) {
      toast.error('Adicione uma comissão!');
      return;
    }

    setComissoes(prev => [...prev, novaComissao]);
    setNovaComissao('');
  }

  const handleAddFrentes = () => {
    if (!novaFrente) {
      toast.error('Adicione uma Frente!');
      return;
    }

    setFrentes(prev => [...prev, novaFrente]);
    setNovaFrente('');
  }

  const handleAddLegislaturas = () => {
    if (!novaLegislatura) {
      toast.error('Adicione uma Frente!');
      return;
    }

    setLegislaturas(prev => [...prev, novaLegislatura]);
    setNovaLegislatura('');
  }

  const handleAddTitulo = () => {
    if (!novoTitulo) {
      toast.error('Adicione um título!');
      return;
    }

    setTitulos(prev => [...prev, novoTitulo]);
    setNovoTitulo('');
  }

  const removeComissao = (comissao: string) => {
    const filtered = comissoes.filter(item => item !== comissao);
    setComissoes(filtered);
  }

  const removeFrente = (frente: string) => {
    const filtered = frentes.filter(item => item !== frente);
    setFrentes(filtered);
  }

  const removeLegislatura = (legislatura: string) => {
    const filtered = legislaturas.filter(item => item !== legislatura);
    setLegislaturas(filtered);
  }

  const removeTitulo = (titulo: string) => {
    const filtered = titulos.filter(item => item !== titulo);
    setTitulos(filtered);
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const newSelectedFile = e.target.files[0];
      setSelectedFile(newSelectedFile);

      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(newSelectedFile);

      return;
    }

    toast.error('Nenhuma imagem selecionado!');
  };

  const getPartidos = async () => {
    const response = await axios.get('https://camaraosasco.mavidey.com.br/government_party');
    const data = response.data;
    setAllPartidos(data)
  }

  const handleAddPerson = async () => {

    if (!selectedFile || !nome || !email || !nascimento || !partido || !telefone || !numMatricula || !biografria || !comissoes || !endereco || !frentes || !grau || !legislaturas || !naturalidade) {
      toast.error('Preencha os campos para continuar!');
      return;
    }

    setLoading(true);
    try {

      const partidoEscolhido = allPartidos.filter((item: any) => item.name === partido);
      const partidoInfo = partidoEscolhido[0];

      const data = {
        biography: biografria,
        commision: comissoes,
        email: email,
        address: endereco,
        parliamentary_front: frentes,
        level_education: grau,
        legislature: legislaturas,
        birth_date: nascimento,
        naturalness: naturalidade,
        name: nome,
        ocupation: ocupacao,
        id_government_party: partidoInfo.id_government_party,
        facebook: facebook,
        instagram: instagram,
        telephone: telefone,
        registration: numMatricula,
        title: titulos
      }

      const formData = new FormData();
      formData.append('profile', selectedFile);
      formData.append('data', JSON.stringify(data));

      const response = await axios.post(`https://camaraosasco.mavidey.com.br/politician`, formData, {
        withCredentials: true
      })

      console.log(response.data)


      toast.success('Vereador adicionado com sucesso!');
      addCount();
      window.location.reload();


      // let imageUrl = '';

      // const snapshot = await firebase.storage().ref().child(nome).put(selectedFile);
      // const url = await snapshot.ref.getDownloadURL();
      // if (url) {
      //   imageUrl = url
      // };

      // const redes = {
      //   facebook: facebook,
      //   instagram: instagram
      // }

      // const partidoEscolhido = await allPartidos.filter(item => item.nome === partido);
      // const partidoInfo = partidoEscolhido[0];

      // const data: VereadorData = {
      //   biografia: biografria,
      //   comissoes_permanentes: comissoes,
      //   email: email,
      //   endereco: endereco,
      //   frentes_parlamentares: frentes,
      //   grau_instrucao: grau,
      //   imagem: imageUrl,
      //   legislaturas: legislaturas,
      //   mesa_diretora: mesa,
      //   nascimento: nascimento,
      //   naturalidade: naturalidade,
      //   nome: nome,
      //   ocupacao: ocupacao,
      //   partido: partidoInfo.nome,
      //   partido_logo: partidoInfo.link,
      //   redes_sociais: redes,
      //   telefone: telefone,
      //   titulos: titulos,
      //   whatsapp: whatsapp,
      //   numMatricula: numMatricula
      // }

      // // console.log(data)

      // await firebase.firestore().collection('vereadores').add({ ...data })
      //   .then(() => {
      //     toast.success('Vereador adicionado com sucesso!');
      //     addCount();
      //     // router.reload();
      //     setBiografia('');
      //     setComissoes([]);
      //     setEmail('');
      //     setEndereco('');
      //     setFrentes([]);
      //     setGrau('');
      //     // setSelectedFile(null);
      //     // setPreviewUrl('')
      //     setLegislaturas([]);
      //     setMesa('');
      //     setNascimento('');
      //     setNaturalidade('');
      //     setNome('');
      //     setOcupacao('');
      //     setPartido('');
      //     setFacebook('');
      //     setInstagram('');
      //     setTelefone('');
      //     setTitulos([]);
      //     setWhatsapp('');
      //     setNumMatricula('');
      //   })

    }
    catch (error) {
      console.log(error)
    }
    finally {
      setLoading(false);
    }
  }

  // const handleallPartidos = async () => {
  //   await getPartidos();
  //   setallPartidos([...PARTIDOS_IMG, ...allPartidos])
  // }

  useEffect(() => {
    getPartidos();
  }, [])

  return (
    <Container>

      {
        previewUrl &&
        <img
          src={previewUrl}
          style={{ width: 200 }}
        />
      }

      <input type="file"
        onChange={handleFileChange}
        placeholder="Foto"
      />

      <textarea
        value={biografria}
        onChange={e => setBiografia(e.target.value)}
        placeholder="Biografia"
      ></textarea>

      <input type="text"
        value={novaComissao}
        onChange={e => setNovaComissao(e.target.value)}
        placeholder="Comissões"
      />
      <button onClick={() => handleAddComissoes()}>Adicionar Comissão</button>

      <div className='areaArray'>
        {
          comissoes.length > 0 &&
          comissoes.map(comissao => (
            <div key={comissao} className="areaItemArray">
              <p>{comissao}</p>
              <button onClick={() => removeComissao(comissao)}>X</button>
            </div>
          ))
        }
      </div>

      <input type="email"
        value={email}
        onChange={e => setEmail(e.target.value)}
        placeholder="E-mail"
      />

      <input type="text"
        value={endereco}
        onChange={e => setEndereco(e.target.value)}
        placeholder="Endereço"
      />

      <input type="text"
        value={novaFrente}
        onChange={e => setNovaFrente(e.target.value)}
        placeholder="Frentes Parlamentares"
      />
      <button onClick={() => handleAddFrentes()}>Adicionar Frente Parlamentar</button>

      <div className='areaArray'>
        {
          frentes.length > 0 &&
          frentes.map(frente => (
            <div key={frente} className="areaItemArray">
              <p>{frente}</p>
              <button onClick={() => removeFrente(frente)}>X</button>
            </div>
          ))
        }
      </div>

      <input type="text"
        value={grau}
        onChange={e => setGrau(e.target.value)}
        placeholder="Grau instrução"
      />

      <input type="text"
        value={novaLegislatura}
        onChange={e => setNovaLegislatura(e.target.value)}
        placeholder="Legislaturas"
      />
      <button onClick={() => handleAddLegislaturas()}>Adicionar Legislatura</button>

      <div className='areaArray'>
        {
          legislaturas.length > 0 &&
          legislaturas.map(legislatura => (
            <div key={legislatura} className="areaItemArray">
              <p>{legislatura}</p>
              <button onClick={() => removeLegislatura(legislatura)}>X</button>
            </div>
          ))
        }
      </div>

      <input type="date"
        value={nascimento}
        onChange={e => setNascimento(e.target.value)}
        placeholder="Nascimento (00/00/0000)"
      />

      <input type="text"
        value={naturalidade}
        onChange={e => setNaturalidade(e.target.value)}
        placeholder="Naturalidade"
      />

      <input type="text"
        value={nome}
        onChange={e => setNome(e.target.value)}
        placeholder="Nome"
      />

      <input type="text"
        value={ocupacao}
        onChange={e => setOcupacao(e.target.value)}
        placeholder="Ocupação"
      />

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <select onChange={e => setPartido(e.target.value)}>
          <option value=''>Selecione um partido</option>
          {
            allPartidos.map((partido: any) => (
              <option key={partido.name} value={partido.name}>{partido.name}</option>
            ))
          }
        </select>
        <button onClick={() => getPartidos()} style={{ height: 40, marginLeft: 5, borderRadius: 5 }}>
          <FiRefreshCw />
        </button>
      </div>

      <input type="url"
        value={facebook}
        onChange={e => setFacebook(e.target.value)}
        placeholder="Facebook"
      />
      <input type="url"
        value={instagram}
        onChange={e => setInstagram(e.target.value)}
        placeholder="Instagram"
      />

      <input type="text"
        value={telefone}
        onChange={e => setTelefone(e.target.value)}
        placeholder="Telefone"
      />

      <input type="text"
        value={novoTitulo}
        onChange={e => setNovoTitulo(e.target.value)}
        placeholder="Titulo"
      />
      <button onClick={() => handleAddTitulo()}>Adicionar Título</button>

      <div className='areaArray'>
        {
          titulos.length > 0 &&
          titulos.map(titulo => (
            <div key={titulo} className="areaItemArray">
              <p>{titulo}</p>
              <button onClick={() => removeTitulo(titulo)}>X</button>
            </div>
          ))
        }
      </div>

      <input type="text"
        value={numMatricula}
        onChange={e => setNumMatricula(e.target.value)}
        placeholder="Número da Matrícula"
      />


      <button className="send" onClick={() => handleAddPerson()} disabled={loading}>
        {loading ? 'Carregando...' : 'Adicionar Vereador'}
      </button>
    </Container>
  )

}
