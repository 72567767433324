import Header from "../../components/Header";
import Vereadores from "../../components/Vereadores";
import { useEffect, useState } from "react";
import firebase from '../../services/firebaseConnect';

import { VereadorData } from "../../types/vereador";
import { SolicitacaoData } from "../../types/solicitacao";

import {
  FiUser,
  FiArchive,
  FiVideo,
  FiRefreshCw
} from 'react-icons/fi';

import { ButtonNav, Container } from './styles';
import AddVereador, { PARTIDOS_DATA } from "../../components/AddVereador";
import Solicitacoes from "../../components/Solicitacoes";
import { toast } from "react-hot-toast";
import Lives from "../../components/Lives";
import AddPartido from "../../components/AddPartido";
import axios from "axios";
import AddVideosTitle from "../../components/AddVideosTitle";
import { url } from "inspector";
import Cookies from "js-cookie";

export default function Dashboard() {

  const [selectedNav, setSelectedNav] = useState('');
  const [vereadores, setVereadores] = useState<VereadorData[] | []>([]);
  const [solicitacoes, setSolicitacoes] = useState<SolicitacaoData[] | []>([]);
  const [partidos, setPartidos] = useState<any[] | []>([]);
  const [lives, setLives] = useState('');
  const [vereadoresCount, setVereadoresCount] = useState(0);
  const [videosYTDB, setVideosYTDB] = useState()

  const getVereadores = async () => {


    const response = await axios.get('https://camaraosasco.mavidey.com.br/politician', {
      headers: {
        'Cache-Control': 'no-cache'
      }
    });
    const data = response.data;
    setVereadores(data)
    setVereadoresCount(data.length)

    // await firebase.firestore().collection('vereadores').get()
    //   .then(snapshot => {

    //     setVereadores([]);
    //     const vereadoresList = [] as VereadorData[];

    //     snapshot.docs.map(doc => {

    //       const newPerson: VereadorData = {
    //         id: doc.id,
    //         biografia: doc.data().biografia,
    //         mesa_diretora: doc.data().mesa_diretora,
    //         naturalidade: doc.data().naturalidade,
    //         legislaturas: doc.data().legislaturas,
    //         ocupacao: doc.data().ocupacao,
    //         telefone: doc.data().telefone,
    //         redes_sociais: doc.data().redes_sociais,
    //         grau_instrucao: doc.data().grau_instrucao,
    //         partido_logo: doc.data().partido_logo,
    //         imagem: doc.data().imagem,
    //         email: doc.data().email,
    //         titulos: doc.data().titulos,
    //         endereco: doc.data().endereco,
    //         nascimento: doc.data().nascimento,
    //         frentes_parlamentares: doc.data().frentes_parlamentares,
    //         partido: doc.data().partido,
    //         nome: doc.data().nome,
    //         comissoes_permanentes: doc.data().comissoes_permanentes,
    //         whatsapp: doc.data().whatsapp,
    //         numMatricula: doc.data().numMatricula
    //       }

    //       vereadoresList.push(newPerson);

    //     })

    //     setVereadores(vereadoresList);
    //     setVereadoresCount(vereadoresList.length);

    //   })

  }

  const getPartidos = async () => {
    const response = await axios.get('https://camaraosasco.mavidey.com.br/government_party');
    const data = response.data;
    console.log("partidos", data)

    setPartidos(data)

  }

  const getVideosYTDB = async () => {
    const videosRes = await axios.get(
      'https://www.googleapis.com/youtube/v3/search?key=AIzaSyBXavktra5jgihdK7t7K5w-HwmulkVsZ6Y&channelId=UC1Cub_1zWejXVHXbeslA4AA&maxResults=50&order=date&'
    );

    const videos = await videosRes.data.items.map((video: any) => {
      return {
        id: video.id.videoId,
        link: `https://www.youtube.com/watch?v=${video.id.videoId}`,
        thumbnail: `https://img.youtube.com/vi/${video.id.videoId}/0.jpg`,
        title: ''
      };
    });

    const filteredVideos = await getVideoTitle(videos);
    setVideosYTDB(filteredVideos);
  }

  async function getVideoTitle(videos: any) {
    const snapshot = await firebase.firestore().collection('videosYT').get();

    const videosYTData = snapshot.docs.reduce((acc, doc) => {
      // @ts-ignore
      acc[doc.id] = doc.data().title;
      return acc;
    }, {});

    // @ts-ignore
    videos.forEach((video) => {
      const videoId = video.id;
      // @ts-ignore
      const titleFromFirestore = videosYTData[videoId];

      video.title = titleFromFirestore || '';
    });

    return videos;
  }


  const getSolicitacoes = async () => {

    const config = {
      withCredentials: true,
      url: 'https://camaraosasco.mavidey.com.br/request',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const response = await axios(config);
    const data = response.data;
    setSolicitacoes(data)

  }

  const getLives = async () => {
    await firebase.firestore().collection('lives').doc('current_live').get()
      .then(snapshot => {
        const result = snapshot.data()?.url;
        setLives(result)
      })
  }



  const sendNotification = async () => {
    const allTokens = [] as string[];
    await firebase.firestore().collection('tokens').get()
      .then(snapshot => {

        snapshot.forEach(doc => {
          allTokens.push(doc.data().token);
        })

      })

    const msg = {
      to: allTokens,
      sound: 'default',
      title: 'Câmara Osasco, estamos ao vivo!',
      body: 'Assista agora mesmo nossa transmissão ao vivo.'
    }



    // await axios.post('https://us-central1-teste-osasco.cloudfunctions.net/sendNotification', msg)
    await axios.post('/api/sendNotification', msg)
      .then(res => {
        console.log(res)
        toast.success('Notificação enviada com sucesso!')
      })
      .catch(error => {
        console.log('ERROR NOTIFICATION: ', error);
      })

    // await axios.post('http://localhost:3001/sendNotification', msg)
    //   .then(() => {
    //     toast.success('Notificação enviada com sucesso!')
    //   })
    //   .catch(error => {
    //     console.log('ERROR NOTIFICATION: ', error);
    //   })
  }

  useEffect(() => {
    getVereadores();
    getSolicitacoes();
    getLives();
    getPartidos();
    getVideosYTDB();
  }, [])

  return (
    <>
      <Header />
      <Container>
        <div className="navarea">
          <div className="navitem">
            <div className="row">
              <FiArchive />
              <div className="reloadArea">
                <h5>{solicitacoes.length}</h5>
                <button onClick={() => {
                  getSolicitacoes()
                  setSelectedNav('')
                }}>
                  <FiRefreshCw />
                </button>
              </div>
            </div>
            <ButtonNav
              disabled={true}
              onClick={() => selectedNav === 'solicitacoes' ? setSelectedNav('') : setSelectedNav('solicitacoes')}
              isActive={selectedNav === 'solicitacoes' ? true : false}
            >{selectedNav === 'solicitacoes' ? 'Fechar' : 'Solicitações'}</ButtonNav>
          </div>
          <div className="navitem">
            <div className="row">
              <FiUser />
              <div className="reloadArea">
                <h5>{vereadoresCount}</h5>
                <button onClick={() => {
                  getVereadores()
                  getPartidos()
                  setSelectedNav('')
                }}>
                  <FiRefreshCw />
                </button>
              </div>
            </div>
            <ButtonNav
              onClick={() => selectedNav === 'vereadores' ? setSelectedNav('') : setSelectedNav('vereadores')}
              isActive={selectedNav === 'vereadores' ? true : false}
            >{selectedNav === 'vereadores' ? 'Fechar' : 'Vereadores'}</ButtonNav>
            <ButtonNav
              onClick={() => selectedNav === 'addvereador' ? setSelectedNav('') : setSelectedNav('addvereador')}
              isActive={selectedNav === 'addvereador' ? true : false}
            >{selectedNav === 'addvereador' ? 'Fechar' : 'Adicionar vereador'}</ButtonNav>
            <ButtonNav
              onClick={() => selectedNav === 'addpartido' ? setSelectedNav('') : setSelectedNav('addpartido')}
              isActive={selectedNav === 'addpartido' ? true : false}
            >{selectedNav === 'addpartido' ? 'Fechar' : 'Gerenciar partidos'}</ButtonNav>
          </div>
          <div className="navitem">
            <div className="row">
              <FiVideo />
              <div className="reloadArea">
                <button onClick={() => {
                  getLives()
                  // getThumbs()
                  setSelectedNav('')
                }}>
                  <FiRefreshCw />
                </button>
              </div>
            </div>
            <ButtonNav
              onClick={() => selectedNav === 'live' ? setSelectedNav('') : setSelectedNav('live')}
              isActive={selectedNav === 'live' ? true : false}
            >{selectedNav === 'live' ? 'Fechar' : 'Gerenciar live'}</ButtonNav>
            <ButtonNav
              onClick={() => sendNotification()}
              isActive={false}
            >Enviar notificação</ButtonNav>
            <ButtonNav
              onClick={() => selectedNav === 'videoYoutube' ? setSelectedNav('') : setSelectedNav('videoYoutube')}
              isActive={selectedNav === 'videoYoutube' ? true : false}
            >{selectedNav === 'videoYoutube' ? 'Fechar' : 'Título dos vídeos'}</ButtonNav>
          </div>
        </div>
      </Container>
      {
        selectedNav === 'addvereador' && <AddVereador addCount={() => setVereadoresCount(vereadoresCount + 1)} partidos={partidos} />
      }
      {
        selectedNav === 'vereadores' && <Vereadores dataSolicitacoes={solicitacoes} partidos={partidos} data={vereadores} removeCount={() => setVereadoresCount(vereadoresCount - 1)} />
      }
      {
        selectedNav === 'solicitacoes' && <Solicitacoes data={solicitacoes} />
      }
      {
        selectedNav === 'live' && <Lives url={lives} getLives={() => getLives()} />
      }
      {
        selectedNav === 'addpartido' && <AddPartido data={partidos} getPartidos={() => getPartidos()} />
      }
      {
        // @ts-ignore
        selectedNav === 'videoYoutube' && <AddVideosTitle data={videosYTDB} />
      }
    </>
  );
}
