import { Routes, Route } from 'react-router-dom';

import SignIn from '../screens/signIn';
import Dashboard from '../screens/dashboard';
import PrivacyPolicy from '../screens/privacy-policy';
//
import LoginVereador from '../screens/loginVereador';
import { ResetPassword } from '../screens/resetPassword';
import DashVereador from '../screens/dashVereador';

import Private from './Private';
import { useContext } from 'react';
import { AuthContext } from '../contexts/auth';

export default function RoutesApp() {

  const { user } = useContext(AuthContext);

  return (
    <Routes>
      <Route path='/' element={<SignIn />} />
      <Route path='/login-vereador' element={<LoginVereador />} />
      <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      {/* resetPassword */}
      <Route path='/reset-password' element={<ResetPassword />} />
      {
        user?.permission === 'ADMIN' && <Route path='/dashboard' element={<Private><Dashboard /></Private>} />
      }

    </Routes>
  )
}
