import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import firebase from '../../services/firebaseConnect';
import { v4 as uuidV4 } from 'uuid';

import {
  Container
} from './styles';

import { VereadorData } from '../../types/vereador';
import axios from 'axios';

type AccessScreenProps = {
  data: any,
  closeAcessos: () => void;
}

type AccessProps = {
  uid?: string;
  nome: string;
  email: string;
  matricula: string;
  type: string;
  active: boolean;
  vereador: {
    id: string;
    nome: string;
    email: string;
    imagem?: string;
  };
};

export default function AddAccess({ data, closeAcessos }: AccessScreenProps) {

  const [allAccess, setAllAccess] = useState<AccessProps[] | []>([]);
  const [editAccess, setEditAccess] = useState<AccessProps | null>(null);
  const [selectedVereador, setSelectedVereador] = useState<any | null>(null);
  const [showVereadores, setShowVereadores] = useState(false);

  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [matricula, setMatricula] = useState('');
  const [pass, setPass] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClearSelect = () => {
    setSelectedVereador(null)
    setNome('');
    setEmail('');
    setMatricula('');
  }

  useEffect(() => {
    getAccess();
  }, [])

  const handleEditSelect = (acessor: AccessProps) => {
    setEditAccess(acessor);
    setNome(acessor.nome);
    setEmail(acessor.email);
    setMatricula(acessor.matricula);
  }

  const handleEditClose = () => {
    setEditAccess(null);
    setNome('');
    setEmail('');
    setMatricula('');
  }

  const getAccess = async () => {
    setAllAccess([]);

    console.log("data", data)

    const response = await axios.get(`https://camaraosasco.mavidey.com.br/politician/users/${data.id_politician}`, {
      withCredentials: true
    })


    setAllAccess(response.data);

  }

  const handleUpdateAcess = () => {

    if (editAccess === null) return;

    setLoading(true);
    try {
      firebase.firestore().collection('access').doc(editAccess.uid!).update({
        nome: nome || editAccess.nome,
        email: email || editAccess.email,
        matricula: matricula || editAccess.matricula
      })
        .then(async () => {
          const current = editAccess;
          const filtered = await allAccess.filter(item => item.uid! !== current.uid!);
          current.nome = nome || current.nome;
          current.email = email || current.email;
          current.matricula = matricula || current.matricula;
          setAllAccess([...filtered, current]);
          handleClearSelect();
          toast.success('Assessor editado com sucesso!')
        })
    }
    catch (error) {
      console.log('ERROR: ', error);
    }
    finally {
      setLoading(false);
    }
  }

  const handleAddAccess = async () => {
    if (!nome || !email || !matricula || !pass) {
      toast.error('Preencha todos os campos!');
      return;
    }

    setLoading(true);
    try {

      const response = await axios.post('https://camaraosasco.mavidey.com.br/politician/advisor-politician', {
        name: nome,
        email: email,
        registration: matricula,
        password: pass,
        id_politician: data.id_politician
      }, {
        withCredentials: true
      })

      toast.success('Assessor adicionando com sucesso!');
      setNome('');
      setEmail('');
      setMatricula('');
      setPass('');
      window.location.reload();

    }
    catch (error: any) {
      toast.error(error.response.data.error);
      return;
    }
    finally {
      setLoading(false);
    }

  }

  const removeAccess = async (access: any) => {

    setLoading(true);

    try {

      const response = await axios.delete(`https://camaraosasco.mavidey.com.br/politician/advisor-politicians/${access.id_user}`, {
        withCredentials: true
      })

      toast.success('Assessor removido com sucesso!');
      getAccess();
      // window.location.reload();



    }
    catch (error) {
      console.log('ERROR: ', error);
    }
    finally {
      setLoading(false);
    }

  }

  return (
    <Container>

      <div className="headerArea">
        <h1>{data.nome}</h1>
        <button onClick={closeAcessos}>Voltar</button>
      </div>

      <hr />
      <br />

      <>
        <div className="headerArea">
          <h3>
            {editAccess ? 'Editando acesso' : 'Cadastrar acesso'}
          </h3>
          {
            editAccess &&
            <button style={{ background: '#999' }} onClick={() => handleEditClose()}>Cancelar edição</button>
          }
        </div>

        <input type="text"
          value={nome}
          onChange={e => setNome(e.target.value)}
          placeholder="Nome"
        />

        <input type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder="E-mail"
        />

        <input type="text"
          value={matricula}
          onChange={e => setMatricula(e.target.value)}
          placeholder="Matricula"
        />

        {
          editAccess === null &&
          <input type="text"
            value={pass}
            onChange={e => setPass(e.target.value)}
            placeholder="Senha de acesso"
          />
        }

        <button className="send" onClick={() => editAccess ? handleUpdateAcess() : handleAddAccess()} disabled={loading}>
          {loading ? 'Carregando...' : 'Salvar Acesso'}
        </button>
      </>

      <h3 style={{ marginTop: 30 }}>Acessos cadastrados:</h3>
      <div style={{ width: '100%', height: 2, backgroundColor: '#ddd', marginTop: 10, marginBottom: 10 }}></div>
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '10px', flexWrap: 'wrap' }}>
        {
          allAccess?.map((item: any) => {
            return (
              <div id={item.uuid} style={{ display: 'flex', background: '#ddd', borderRadius: 5, alignItems: 'center', paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10, flexWrap: 'wrap', gap: 5, flexDirection: 'column' }}>
                <div style={{ marginRight: 10 }}>
                  <p style={{ fontWeight: 'bold' }}>{item.name}</p>
                  <p>{item.email}</p>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: 10, alignSelf: 'flex-start' }}>
                  <button onClick={() => removeAccess(item)} disabled={loading}>{loading ? '...' : 'Remover'}</button>
                </div>
              </div>
            )
          })
        }
      </div>
    </Container>
  )

}
