import { useState } from 'react';
import { toast } from 'react-hot-toast';
import firebase from '../../services/firebaseConnect';
import { v4 as uuidV4 } from 'uuid';

import {
  Container
} from './styles';

import { PARTIDOS_DATA } from '../AddVereador';
import axios from 'axios';

type PartidoScreenProps = {
  data: PARTIDOS_DATA[],
  getPartidos: () => void;
}

export default function AddPartido({ data, getPartidos }: PartidoScreenProps) {


  console.log('data', data) 

  const [allPartidos, setAllPartidos] = useState<PARTIDOS_DATA[] | []>(data)

  const [nome, setNome] = useState('');
  const [loading, setLoading] = useState(false);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState('')

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const newSelectedFile = e.target.files[0];
      setSelectedFile(newSelectedFile);

      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(newSelectedFile);

      return;
    }

    toast.error('Nenhuma imagem selecionado!');
  };

  const handleAddPartido = async () => {

    if (!selectedFile || !nome) {
      toast.error('Preencha os campos para continuar!');
      return;
    }

    setLoading(true);
    try {


      const form = new FormData();
      form.append('profile', selectedFile);
      form.append('data', JSON.stringify({ name: nome }));

      const response = await axios.post('https://camaraosasco.mavidey.com.br/government_party', form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true
      });


      if (response.status === 200) {
        getPartidos();
        toast.success('Partido adicionado com sucesso!');
        setNome('');
        setPreviewUrl('');
      }



    }
    catch (error: any) {
      // console.log(error)
      if(error.response.status === 400) {
        toast.error('Partido já cadastrado!')
      }

    }
    finally {
      setLoading(false);
    }
  }

  const removePartido = async (partido: any) => {

    setLoading(true);

    try {

      console.log('partido', partido)

      const response = await axios.delete(`https://camaraosasco.mavidey.com.br/government_party/${partido.id_government_party}`, {
        withCredentials: true
      });

      if (response.status === 200) {
        getPartidos();
        toast.success('Partido removido com sucesso!');
      }

    }
    catch (error:any) {

      if (error.response.status === 400) {
        toast.error('Partido possui políticos vinculados!')
      }
      
    }
    finally {
      setLoading(false)
    }

  }

  return (
    <Container>

      {
        previewUrl &&
        <img
          src={previewUrl}
          style={{ width: 200 }}
        />
      }

      <input type="file"
        onChange={handleFileChange}
        placeholder="Foto"
      />

      <input type="text"
        value={nome}
        onChange={e => setNome(e.target.value)}
        placeholder="Nome do partido"
      />

      <button className="send" onClick={() => handleAddPartido()} disabled={loading}>
        {loading ? 'Carregando...' : 'Adicionar Partido'}
      </button>
      <div style={{ width: '100%', height: 2, backgroundColor: '#ddd', marginTop: 10, marginBottom: 10 }}></div>
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '10px', flexWrap: 'wrap' }}>
        {
          data.map((item: any) => (
            <div id={item.id_government_party} className='cardPartido'>
              <img src={item.image?.includes("https://firebasestorage") ? item.image : `https://camaraosasco.mavidey.com.br/${item.image}`} alt={item.name} />
              <p>{item.name}</p>
              <button onClick={() => removePartido(item)} disabled={loading}>Remover</button>
            </div>
          ))
        }
      </div>
    </Container>
  )

}
